<template>
  <v-row>
    <v-col cols="12" class="d-flex align-center">
      <h2>{{ $t('lotto_game.head') }}</h2>
      <v-divider class="mx-3" vertical />
      <v-breadcrumbs :items="breadcrumbsItems" divider=">>" />
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t('lotto_game.create_head') }}</v-card-title>

        <v-form ref="form">
          <v-divider class="mx-3" />
          <!-- Title Input -->
          <v-card-actions>
            <v-row class="d-flex flex-column">
              <v-col cols="12" xs="6" md="6" xl="6">
                <v-text-field v-model="formData.title" :label="$t('lotto_game.detail_input1')" type="text" outlined dense
                  disabled></v-text-field>
              </v-col>

              <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                <v-text-field v-model="formData.round" :label="$t('lotto_game.detail_input2')" type="date" :min="minDate"
                  outlined dense disabled></v-text-field>
              </v-col>

              <v-row class="ma-0">
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <v-text-field v-model="formData.timeStart" :label="$t('lotto_game.detail_input3')" type="date"
                    :min="minDate" outlined dense disabled></v-text-field></v-col>
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <v-text-field v-model="formData.timeEnd" :label="$t('lotto_game.detail_input4')" type="date"
                    :min="minDate" outlined dense disabled></v-text-field></v-col>
              </v-row>

              <v-row class="ma-0">
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <v-select v-model="formData.typeBetUse" :items="rewardTypes" item-text="name" item-value="value"
                    :label="$t('lotto_game.detail_input5')" outlined dense disabled></v-select>
                </v-col>
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <v-text-field v-model="formData.betUse" :label="$t('lotto_game.detail_input6')" outlined dense
                    disabled></v-text-field>
                </v-col>
              </v-row>
            </v-row>
          </v-card-actions>

          <!-- Reward type and amount Input -->
          <v-col>
            <v-col>
              <v-row>
                <v-card-title class="pt-0 pb-2 px-3"> {{ $t('lotto_game.reward3_head') }}</v-card-title>
              </v-row>
              <v-row>
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <v-select v-model="formData.typeReward" :items="rewardTypes"
                    :rules="[v => !!v || this.$t('lotto_game.select_reward_type')]" :label="$t('lotto_game.reward_type')"
                    outlined dense disabled></v-select>
                </v-col>
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <v-text-field v-model="formData.rewardLastThreeDigits" :label="$t('lotto_game.amount')" type="number"
                    outlined dense disabled></v-text-field>
                </v-col>
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <v-text-field v-model="formData.multiplyTurnOverThreeDigits" :label="$t('lotto_game.reward_multiple')"
                    type="number" outlined dense disabled></v-text-field>
                </v-col>
              </v-row>
            </v-col>

            <v-col>
              <v-row>
                <v-card-title class="pt-0 pb-2 px-3">{{ $t('lotto_game.reward2_head') }}</v-card-title>
              </v-row>
              <v-row>
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <v-select v-model="formData.typeReward" :items="rewardTypes"
                    :rules="[v => !!v || this.$t('lotto_game.select_reward_type')]" :label="$t('lotto_game.reward_type')"
                    outlined dense disabled></v-select>
                </v-col>
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <v-text-field v-model="formData.rewardLastTwoDigits" :label="จำนวน" type="$t('lotto_game.amount')"
                    outlined dense disabled></v-text-field>
                </v-col>
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <v-text-field v-model="formData.multiplyTurnOverTwoDigits" :label="$t('lotto_game.reward_multiple')"
                    type="number" outlined dense disabled></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-col>

          <v-col>
            <v-col>
              <v-row>
                <v-card-title class="pt-0 pb-2 px-3">{{ $t('lotto_game.reward3_head2') }}</v-card-title>
              </v-row>
              <v-row>
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <v-text-field v-model="formResultData.resultThreeDigitsOne" :label="$t('lotto_game.reward1')"
                    :placeholder="$t('lotto_game.alert6')" maxLength="3" outlined dense
                    :disabled="disabledResult"></v-text-field>
                </v-col>
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <v-text-field v-model="formResultData.resultThreeDigitsTwo" :label="$t('lotto_game.reward2')"
                    :placeholder="$t('lotto_game.alert6')" maxLength="3" outlined dense
                    :disabled="disabledResult"></v-text-field>
                </v-col>
              </v-row>
            </v-col>

            <v-col>
              <v-row>
                <v-card-title class="pt-0 pb-2 px-3">{{ $t('lotto_game.reward2_head2') }}</v-card-title>
              </v-row>
              <v-row>
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <v-text-field v-model="formResultData.resultTwoDigits" :label="$t('lotto_game.reward')"
                    :placeholder="$t('lotto_game.alert7')" maxLength="2" outlined dense
                    :disabled="disabledResult"></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-col>

          <v-card-actions>
            <v-spacer />
            <v-btn color="warning" @click="$router.push({ name: 'predict-lotto' })">
              <v-icon>mdi-arrow-left</v-icon>
              <span class="px-3">{{ $t('back') }}</span>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import StaffLimitAccessPage from '@/components/staff/StaffLimitAccessPage.vue'
import store from '@/store'
import moment from 'moment'

export default {
  components: {
    StaffLimitAccessPage,
  },
  data() {
    return {
      loading: false,
      formData: {
        title: '',
        round: '',
        timeStart: '',
        timeEnd: '',
        typeBetUse: 'coin',
        betUse: 1,
        typeReward: 'money',
        rewardLastTwoDigits: null,
        multiplyTurnOverTwoDigits: 0,
        rewardLastThreeDigits: null,
        multiplyTurnOverThreeDigits: 0,
      },
      formResultData: {
        resultThreeDigitsTwo: '',
        resultThreeDigitsOne: '',
        resultTwoDigits: '',
      },
      minDate: moment().format('YYYY-MM-DD'),
      rewardTypes: ['point', 'coin', 'money'],
      disabledResult: true,

      breadcrumbsItems: [
        {
          text: this.$t('lotto_game.head'),
          disabled: false,
          href: '/predictLotto',
        },
        {
          text: this.$t('lotto_game.create_head2'),
          disabled: true,
          href: '',
        },
      ],

      acceptItems: [
        {
          text: this.$t('yes'),
          value: 'yes',
        },
        {
          text: this.$t('not'),
          value: 'no',
        },
      ],
      roles: [],
    }
  },
  async created() {
    this.addLogPage()
    try {
      this.roles = await this.$store.dispatch('getRoleData')
    } catch (e) { }

    this.getLottoData()
  },
  methods: {
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await axios.get('https://api.ipify.org?format=json').then(res =>
          (data = {
            ip: res.data.ip,
            name: 'ทายหวย',
            url: window.location.href,
            detail: 'รายละเอียด ทายหวย',
            admin: userSystem.name ? userSystem.name : '' ? userSystem.name : '',
          }),
        )
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    async getLottoData() {
      const id = this.$route.params.id
      this.loading = true
      try {
        let res = await this.$store.dispatch('getLottoList', `id=${id}`)

        let resData = res.data[0]
        this.formData = {
          title: resData.title,
          round: moment(resData.round).format('YYYY-MM-DD') || null,
          timeStart: moment(resData.timeStart).format('YYYY-MM-DD') || null,
          timeEnd: moment(resData.timeEnd).format('YYYY-MM-DD') || null,
          typeBetUse: resData.typeBetUse,
          betUse: resData.betUse,
          typeReward: resData.typeReward,
          rewardLastTwoDigits: resData.rewardLastTwoDigits,
          multiplyTurnOverTwoDigits: resData.multiplyTurnOverTwoDigits,
          rewardLastThreeDigits: resData.rewardLastThreeDigits,
          multiplyTurnOverThreeDigits: resData.multiplyTurnOverThreeDigits,
        }
        this.formResultData = {
          resultThreeDigitsTwo: resData.resultThreeDigitsTwo || '',
          resultThreeDigitsOne: resData.resultThreeDigitsOne || '',
          resultTwoDigits: resData.resultTwoDigits || '',
        }

        this.loading = false
      } catch (e) { }
    },
  },
}
</script>
